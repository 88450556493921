/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { string, object } from "yup";

/* Components */
import { Form, Formik } from "formik";
import classNames from "classnames/bind";
import styles from "./AuthForm.module.scss";
import AuthInput from "../AuthInput/AuthInput";
import iconEmail from "../../assets/images/label-email.svg";
import iconPassword from "../../assets/images/label-password.svg";
import iconPhone from "../../assets/images/label-phone.svg";
import SmsInput from "../SmsInput/SmsInput";
import {useTranslation} from "react-i18next";

const cx = classNames.bind(styles);
const defaultSmsTime = 59;

const AuthForm = ({
    setServerErrorMessage,
    handleSubmitLogin,
    handleSubmitSms,
    handleSubmitGetSms,
    serverErrorMessage,
    showSmsCode,
    setShowSmsCode,
    sendSmsAuthStatus,
    setSendSmsAuthStatus,
    seconds,
    setSeconds,
    submitForm,
    isSmsAuthLimitReached,
}) => {
    const [isSmsAuth, setSmsAuth] = useState(0);
    const { t } = useTranslation("translation");
    useEffect(() => {
        let interval = null;
        if (showSmsCode && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((sec) => sec - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [showSmsCode, seconds]);

    // useEffect(() => {
    //     if (isSmsAuthLimitReached) {
    //         setSmsAuth(0)
    //     }
    // }, [isSmsAuthLimitReached]);

    const loginBtn = [t("signIn"), t("giveSMS")];
    const smsBtn = [t("signInSms"), t("signInLogin")];

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{isSmsAuth === 0 ? t("titleLogin") : t("titleSms")}</div>
            <Formik
                validationSchema={object({
                    login:
                        isSmsAuth === 0
                            ? string().required().min(1).max(100)
                            : "",
                    phoneNumber:
                        isSmsAuth === 1
                            ? string().required().min(12).max(12)
                            : "",
                    password:
                        isSmsAuth === 0
                            ? string().required().min(4).max(100)
                            : "",
                    // code: sendSmsAuthStatus && sendSmsAuthStatus?.class !== 'error' ? () : "",
                })}
                enableReinitialize
                initialValues={{
                    login: "",
                    phoneNumber: "",
                    password: "",
                    code: ["", "", "", ""],
                }}
                onSubmit={(values, {setFieldValue, setSubmitting}) => {
                    let payload = {
                        data: { ...values,  phoneNumber: values?.phoneNumber?.replace("++","+")},
                    };

                    if (sendSmsAuthStatus && sendSmsAuthStatus?.class !== 'error') {
                        payload = {
                            data: {
                                phoneNumber: values.phoneNumber,
                                code: values.code.join(""),
                            },
                        };

                        if (payload.data.code.length === 4) {
                            handleSubmitGetSms(payload, setSubmitting);
                        } else {
                            setSubmitting(false);
                            setSendSmsAuthStatus({
                                class: "error",
                                text: t("errorCodeLeft"),
                            });
                        }
                        setFieldValue('code',["","","",""]);
                    } else if (isSmsAuth === 0) {
                        handleSubmitLogin(payload, setSubmitting);
                    } else if (isSmsAuth === 1) {
                        handleSubmitSms(payload, setSubmitting);
                        setSeconds(defaultSmsTime);
                    }
                }}
            >
                {(formik) => {
                    let isDisable = Object.keys(formik.errors).length > 0;

                    if (
                        isSmsAuth === 1 &&
                        formik.values.phoneNumber
                            .replace(/[^\d]/g, "")
                            .substr(-10).length <= 4
                    ) {
                        isDisable = true;
                    }

                    const formInpiuts = [
                        [
                            <AuthInput
                                key={0}
                                label={t("login")}
                                icon={iconEmail}
                                handleBlur={formik.handleBlur}
                                isSubmitting={formik.isSubmitting}
                                {...formik.getFieldProps("login")}
                            />,
                            <AuthInput
                                key={1}
                                label={t("password")}
                                icon={iconPassword}
                                handleBlur={formik.handleBlur}
                                {...formik.getFieldProps("password")}
                                blockType="password"
                                isSubmitting={formik.isSubmitting}
                                error={submitForm === "login" && serverErrorMessage}
                            />,
                        ],
                        [
                            <AuthInput
                                key={0}
                                label={t("phone")}
                                icon={iconPhone}
                                handleBlur={formik.handleBlur}
                                isSubmitting={formik.isSubmitting}
                                {...formik.getFieldProps("phoneNumber")}
                                type="tel"
                                showSmsCode={showSmsCode}
                                setShowSmsCode={setShowSmsCode}
                                error={submitForm === "sms" && serverErrorMessage}
                                setFieldVal={formik.setFieldValue}
                            />,
                        ],
                    ];

                    const smsInputs = formik.values.code.map((item, index) => (
                        <SmsInput
                            key={index}
                            index={index}
                            {...formik.getFieldProps(`code[${index}]`)}
                            isSubmitting={formik.isSubmitting}
                            setSendSmsAuthStatus={setSendSmsAuthStatus}
                            submitForm={formik.submitForm}
                            setFieldVal={formik.setFieldValue}
                            isFocus={index === 0}
                            error={
                                sendSmsAuthStatus &&
                                sendSmsAuthStatus.class === "error"
                            }
                        />
                    ));

                    const btnClasses = cx({
                        "btn": true,
                        "btn--loading": formik.isSubmitting,
                    });

                    return (
                        <Form autoComplete="off" className={styles.form}>
                            {formInpiuts[isSmsAuth]}

                            {showSmsCode && isSmsAuth === 1 && (
                                <div className={styles.sms}>
                                    <div className={styles.sms__title}>
                                        {t("enterCode")}
                                    </div>
                                    <div
                                        className={`one-value-input ${styles.sms__row}`}
                                    >
                                        {smsInputs}

                                        {sendSmsAuthStatus && (
                                            <div
                                                className={`${
                                                    styles.smsStatus
                                                } ${
                                                    styles[
                                                        `smsStatus--${sendSmsAuthStatus.class}`
                                                    ]
                                                }`}
                                            >
                                                {sendSmsAuthStatus.text}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {((!isSmsAuthLimitReached && isSmsAuth) || !isSmsAuth) && <button
                                type="submit"
                                disabled={
                                    isDisable ||
                                    (isSmsAuth && showSmsCode && seconds !== 0)
                                    || (isSmsAuth === 0 && (formik?.values?.login?.length === 0 || formik?.values?.password?.length === 0 ))
                                }
                                className={btnClasses}
                                onClick={() => {
                                    setShowSmsCode(false);
                                }}
                            >
                                {!isSmsAuth || !showSmsCode || seconds === 0 ? (
                                    loginBtn[isSmsAuth]
                                ) : (
                                    <>{t("retrySms")} {seconds}{t("sec")}.</>
                                )}
                            </button>}

                            <button
                                type="button"
                                onClick={() => {
                                    setServerErrorMessage("");
                                    // setSendSmsAuthStatus(false);
                                    // setShowSmsCode(false);
                                    setSmsAuth(1 - isSmsAuth);
                                    // formik.resetForm();
                                }}
                                disabled={isSmsAuthLimitReached && !isSmsAuth}
                                className="btn btn--lvl2"
                            >
                                {smsBtn[isSmsAuth]}
                            </button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default AuthForm;
