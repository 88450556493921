// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Auth-module__changeLang___3Xvcp{position:absolute;top:15px;right:15px;display:flex;align-items:center}.Auth-module__changeLang__item___3AElu{text-transform:capitalize;font-size:14px;line-height:18px;color:var(--text-color);font-weight:600}.Auth-module__changeLang__item___3AElu+.Auth-module__changeLang__item___3AElu{margin-left:10px}.Auth-module__changeLang__item--active___2zN8q{color:var(--color-focused) !important;pointer-events:none}.Auth-module__container___wUgIQ{width:100%;margin:0 auto;max-width:470px;display:flex;flex-direction:column;min-height:100vh;height:100%}.Auth-module__logo___2EeFS{padding-top:48px;margin-bottom:40px;width:256px}.Auth-module__logo__link___nD4fV{display:flex;align-items:flex-start}.Auth-module__footer___13Jq9{display:flex;flex-wrap:wrap;padding-bottom:48px;margin-top:auto}.Auth-module__footer___13Jq9 p{font-size:12px;line-height:18px;color:var(--second-color);margin-bottom:6px;flex:0 0 100%}.Auth-module__footer___13Jq9 a{display:flex;font-weight:600;font-size:14px;line-height:22px;white-space:nowrap}.Auth-module__footer___13Jq9 a img{margin-right:5px}.Auth-module__footer___13Jq9 a+a{margin-left:16px}\n", ""]);
// Exports
exports.locals = {
	"changeLang": "Auth-module__changeLang___3Xvcp",
	"changeLang__item": "Auth-module__changeLang__item___3AElu",
	"changeLang__item--active": "Auth-module__changeLang__item--active___2zN8q",
	"container": "Auth-module__container___wUgIQ",
	"logo": "Auth-module__logo___2EeFS",
	"logo__link": "Auth-module__logo__link___nD4fV",
	"footer": "Auth-module__footer___13Jq9"
};
module.exports = exports;
