import { combineHeaders, parsePayload } from "./helpers";

import axios from "./axiosService";

const api = (method, url, payload) => {
    const { headers, data, params } = parsePayload(payload);
    return axios({
        method,
        url,
        headers: combineHeaders(headers),
        params,
        data,
    });
};

// eslint-disable-next-line import/prefer-default-export
export { api };
