/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import logo from "./../../../../../public/images/logo-with-text.svg";
import { api } from "auth/services/api";
import styles from "./Auth.module.scss";

/* Components */
import SnackbarWrapper from "auth/components/SnackbarWrapper";
import AuthForm from "auth/components/Auth/AuthForm";
import mailIcon from "../../assets/images/icon-mail.svg";
import phoneIcon from "../../assets/images/icon-phone.svg";
import {useTranslation} from "react-i18next";

const Auth = () => {
    /* Local state */
    const { t, i18n } = useTranslation("translation");
    const languageList = ["ru", "en"];
    const [serverErrorMessage, setServerErrorMessage] = useState("");
    const [errMess, setErrMess] = useState("");
    const [open, setOpen] = useState(false);
    const [showSmsCode, setShowSmsCode] = useState(false);
    const [sendSmsAuthStatus, setSendSmsAuthStatus] = useState(false);
    const [isSmsAuthLimitReached, setIsSmsAuthLimitReached] = useState(false);
    const [seconds, setSeconds] = useState(0);
    // Запоминание сабмита формы для разделения ошибок
    const [submitForm, setSubmitForm] = useState("login");

    /* Methods */

    /* Вход по логину и паролю */
    const handleSubmitLogin = (payload, setSubmitting) => {
        setServerErrorMessage("");
        setSubmitForm("login")
        api("POST", "/v1/web/login", payload)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    localStorage.setItem(
                        "auth_data",
                        JSON.stringify(response.data.data),
                    );
                    document.location.assign("/web/");
                } else {
                    setServerErrorMessage(t("errors."+response.data.errorCode));
                }
                setSubmitting(false);
                if (!response.data.success) {
                    setTimeout(() => setServerErrorMessage(""), 5000);
                }
            })
            .catch((error) => {
                setOpen(true);
                setErrMess(error.response.data.data[Object.keys(error.response.data.data)[0]][0]);
                setSubmitting(false);
                setTimeout(() => setServerErrorMessage(""), 5000);
            });
    };

    /* Отправка смс кода */
    const handleSubmitSms = (payload, setSubmitting) => {
        setServerErrorMessage("");
        setSubmitForm("sms")
        api("POST", "/v1/web/login/get-sms", payload)
            .then((response) => {
                if (response.data.success) {
                    setShowSmsCode(true);
                    setSendSmsAuthStatus(false);
                    setSeconds(response.data.data.ttl || 0);
                } else if( response.data.errorCode === "SmsWasSentToTheThisNumber" ) {
                    setShowSmsCode(true);
                    setSendSmsAuthStatus(false);
                    setSeconds(response.data.data.ttl || 0);
                } else {
                    setServerErrorMessage(t("errors."+response.data.errorCode));
                }
                setSubmitting(false);
                if (!response.data.success) {
                    setTimeout(() => setServerErrorMessage(""), 5000);
                }
            })
            .catch((error) => {
                setOpen(true);
                setErrMess(error.response.data.data[Object.keys(error.response.data.data)[0]][0]);
                setSubmitting(false);
                setTimeout(() => setServerErrorMessage(""), 5000);
            });
    };

    /* Провверка смс кода */
    const handleSubmitGetSms = (payload, setSubmitting) => {
        setServerErrorMessage("");
        if(!isSmsAuthLimitReached) {
            api("POST", "/v1/web/login/sms", payload)
                .then((response) => {
                    if (response.data.success) {
                        localStorage.setItem(
                            "auth_data",
                            JSON.stringify(response.data.data),
                        );
                        setSendSmsAuthStatus({
                            class: "success",
                            text: t("success"),
                        });
                        document.location.assign("/web/");
                    } else {
                        setSubmitting(false);
                        if (response.data.errorCode === "InvalidCode") {
                            setSendSmsAuthStatus({
                                class: "error",
                                text: `${t("errorCode")} ${t("left")} ${response.data.data.attemptsLeft} ${
                                    response.data.data.attemptsLeft > 1 ? t("attempts") : t("attempt")
                                }`,
                            });
                            // setSeconds(response.data.data.ttl || 0);
                        } else if (response.data.errorCode === "LimitOfAttemptsIsExceeded") {
                            viewLimitMessage();
                            setIsSmsAuthLimitReached(true);
                            // setSeconds(response.data.data.ttl || 0);
                            setTimeout(() => {
                                // setIsSmsAuthLimitReached(false);
                                setSendSmsAuthStatus(false);
                                setShowSmsCode(false);
                            }, (response.data.data.ttl || 0) * 1000);
                        } else if (response.data.errorCode === "NotFound") {
                            setSendSmsAuthStatus({
                                class: "error",
                                text: t("codeTimeLeft"),
                            });
                        } else {
                            setSendSmsAuthStatus({
                                class: "error",
                                text: t("error404"),
                            });
                        }
                    }
                })
                .catch((error) => {
                    setOpen(true);
                    setErrMess(error.response.data.data[Object.keys(error.response.data.data)[0]][0]);
                    setSubmitting(false);
                    setTimeout(() => setServerErrorMessage(""), 5000);
                });
        } else {
            setSubmitting(false);
            viewLimitMessage();
        }
    };

    const viewLimitMessage = () => setSendSmsAuthStatus({
        class: "error",
        text: t("blocked"),
    });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        <div className="wrapper">
            <main>
                 <div className={styles.changeLang}>
                    {languageList.map((langItem, key) => {
                        return (
                            <a
                                href="#"
                                key={key}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (langItem !== i18n.language) {
                                        i18n.changeLanguage(langItem);
                                        window.location.reload();
                                    }
                                }}
                                className={`${styles.changeLang__item} ${langItem === i18n.language ? styles["changeLang__item--active"] : ""}`}
                            >{langItem}</a>
                        )
                    })}
                </div>
                <div className={styles.container}>
                    <div className={styles.logo}>
                        <a href="#" className={styles.logo__link}>
                            <img
                                src={logo}
                                alt="logo text"
                                className="logo__img"
                            />
                        </a>
                    </div>

                    <AuthForm
                        setServerErrorMessage={setServerErrorMessage}
                        serverErrorMessage={serverErrorMessage}
                        showSmsCode={showSmsCode}
                        setShowSmsCode={setShowSmsCode}
                        sendSmsAuthStatus={sendSmsAuthStatus}
                        setSendSmsAuthStatus={setSendSmsAuthStatus}
                        handleSubmitLogin={handleSubmitLogin}
                        handleSubmitSms={handleSubmitSms}
                        handleSubmitGetSms={handleSubmitGetSms}
                        seconds={seconds}
                        setSeconds={setSeconds}
                        submitForm={submitForm}
                        isSmsAuthLimitReached={isSmsAuthLimitReached}
                    />

                    <div className={styles.footer}>
                        <p>
                            {t("ifProblem")}
                        </p>
                        <a href="mailto:support@staq.ru">
                            <img src={mailIcon} alt="" />
                            support@staq.ru
                        </a>
                        <a href="tel:+79268106020">
                            <img src={phoneIcon} alt="" />
                            +7 (926) 810-60-20
                        </a>
                    </div>
                </div>

                <SnackbarWrapper
                    open={open}
                    handleClose={handleClose}
                    errMess={errMess}
                    severity="error"
                />
            </main>
        </div>
    );
};

export default Auth;
