/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./SmsInput.module.scss";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";

const cx = classNames.bind(styles);

const SmsInput = ({
    value,
    error,
    isSubmitting,
    setSendSmsAuthStatus,
    submitForm,
    isFocus,
    setFieldVal,
    index,
    ...props
}) => {
    const { t } = useTranslation("translation");
    const inputClasses = cx({
        "input": true,
        "input--isSubmitting": isSubmitting,
        "input--error": error,
    });

    const smsInput = useRef(null);

    useEffect(() => {
        if (isFocus) {
            smsInput?.current.focus();
        }
    }, []);

    useEffect(() => {
        if (error) {
            smsInput.current.value = "";

            if (index === 0) {
                smsInput.current.focus();
            }
        }
    }, [error]);

    const setSmsCodeKeyup = (e) => {
        const $this = e.target;
        if (
            e.keyCode === 16 ||
            e.keyCode === 9 ||
            e.keyCode === 224 ||
            e.keyCode === 18 ||
            e.keyCode === 17
        ) {
            return;
        }

        if (
            (e.keyCode === 8 || e.keyCode === 37) &&
            $this.previousElementSibling &&
            $this.previousElementSibling.tagName === "INPUT"
        ) {
            $this.previousElementSibling.select();
        } else if (e.keyCode !== 8 && $this.nextElementSibling && $this.nextElementSibling.tagName === "INPUT") {
            $this.nextElementSibling.select();
        }

        if ($this.nextElementSibling === null || $this.nextElementSibling.tagName !== "INPUT") {
            setSendSmsAuthStatus({ class: "wait", text: t("check") });
            submitForm();
        }
    };

    const setSmsCodeKeyDown = (e) => {
        const $this = e.currentTarget;
        const key = e.which;

        if ($this.value.length >= 1) {
            if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                $this.value = "";
                setFieldVal(props.name, "");
            }
        }
    };

    const getPastCode = (e) => {
        e.preventDefault();
        let pastText = Number(e.clipboardData.getData("Text"));

        if (
            Number.isNaN(pastText) === false &&
            pastText.toString().length === 4
        ) {
            pastText = pastText.toString();
        }
    };

    const setSmsCodeFocus = (e) => {
        const $this = e.target;
        const firstInput = e.currentTarget
            ?.closest(".one-value-input")
            ?.querySelector("input");

        if ($this === firstInput) return;

        if (firstInput?.value === "") {
            firstInput.focus();
        }

        if ($this?.previousElementSibling?.value === "") {
            $this.previousElementSibling.focus();
        }
    };

    const setSmsCodeInput = (e) => {
        const $this = e.target;
        const data = e.data || $this.value;
        if (!data) return false;
        if (data.length >= 1) return false;
    };

    const withValueLimit = ({ formattedValue }) =>
        formattedValue.length <= 1 && formattedValue.indexOf("-") < 0;

    return (
        <NumberFormat
            getInputRef={smsInput}
            onPaste={(e) => {
                getPastCode(e);
            }}
            onKeyUp={(e) => {
                setSmsCodeKeyup(e);
            }}
            onKeyDown={(e) => {
                setSmsCodeKeyDown(e);
            }}
            onFocus={(e) => {
                setSmsCodeFocus(e);
            }}
            onInput={(e) => {
                setSmsCodeInput(e);
            }}
            type="tel"
            inputMode="numeric"
            data-min="1"
            {...props}
            decimalScale={0}
            isAllowed={withValueLimit}
            value={value}
            defaultValue={value}
            className={inputClasses}
            onChange={(e) => {
                let inputNum = e.target.value;
                inputNum = parseInt(inputNum)
                setFieldVal(props.name, `${inputNum}`);
            }}
        />
    );
};

export default SmsInput;
