/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import NumberFormat from "react-number-format";
import styles from "./AuthInput.module.scss";

import iconViewOn from "../../assets/images/icon-view--on.svg";
import iconViewOff from "../../assets/images/icon-view--off.svg";
import iconAlarm from "../../assets/images/icon-alarm.svg";
import iconEdit from "../../assets/images/icon-edit.svg";
import { replaceAt } from "../../../system/helpers/date/replaceAt";

const cx = classNames.bind(styles);

const AuthInput = ({
                       type,
                       placeholder,
                       onBlur,
                       handleBlur,
                       value,
                       label,
                       icon,
                       additionalIcon,
                       blockType,
                       error,
                       isSubmitting,
                       showSmsCode,
                       setShowSmsCode,
                       setFieldVal,
                       ...props
                   }) => {
    const boxClasses = cx({
        "inputBox": true,
        "inputBox--focused": value.length > 0,
        "inputBox--isSubmitting": isSubmitting,
        "inputBox--smsCode": showSmsCode,
    });

    const inputClasses = cx({
        "input": true,
        "input--error": error,
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const passVisibleFunc = (e) => {
        setPasswordVisible(!passwordVisible);
    };

    const onPaste = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const clipboardText = e.clipboardData.getData("Text").replace(/([()-\s])/g, '');
        let replaceValue = "";

        if (clipboardText.length === 12) {
            if (clipboardText[0] === "+") {
                replaceValue = clipboardText.replace(/\+7/, "");
            }
        } else if (clipboardText.length === 11) {
            if (clipboardText[0] === "8" || clipboardText[0] === "7") {
                // replaceValue = clipboardText
                replaceValue = clipboardText.slice(1, clipboardText.length);
            }
        } else if (clipboardText.length === 10) {
            replaceValue = clipboardText;
        }

        setFieldVal(props.name, `+7${replaceValue}`);
        return false;
    };

    const withValueLimit = ({ formattedValue }) =>
        formattedValue.length <= 12 && formattedValue.indexOf("-") < 0;

    return (
        <div className={boxClasses}>
            {icon && <img src={icon} className={styles.icon} alt={label} />}
            {type === "tel" ? (
                <NumberFormat
                    type="tel"
                    {...props}
                    value={value}
                    prefix="+"
                    data-min="11"
                    className={inputClasses}
                    defaultValue={value}
                    isAllowed={withValueLimit}
                    onBlur={handleBlur}
                    onPaste={onPaste}
                    decimalScale={0}
                    onChange={(e) => {
                        let inputNum = e.target.value;
                        // Ввод и +7 и +8
                        if (inputNum[1] && inputNum[1] !== "7") {
                            if (inputNum[1] === "8") {
                                inputNum = "7";
                            } else {
                                inputNum = "7" + inputNum;
                            }
                        }
                        setFieldVal(props.name, `${inputNum}`);
                    }}
                />
            ) : (
                <input
                    type={blockType === "password" ? (passwordVisible ? 'text' : 'password') : type}
                    onBlur={handleBlur}
                    value={value || ""}
                    placeholder=""
                    className={inputClasses}
                    {...props}
                />
            )}

            <label className={styles.label}>{label}</label>

            {blockType === "password" && value && (
                <img
                    src={passwordVisible ? iconViewOn : iconViewOff}
                    className={styles.additionalIcon}
                    onClick={passVisibleFunc}
                    alt=""
                />
            )}

            {showSmsCode && (
                <img
                    src={iconEdit}
                    className={styles.additionalIcon}
                    onClick={() => {
                        setShowSmsCode(false);
                    }}
                    alt=""
                />
            )}

            {error && (
                <div className={styles.error}>
                    <img src={iconAlarm} alt="" />
                    {error}
                </div>
            )}
        </div>
    );
};

export default AuthInput;
