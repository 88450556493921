import axios from "axios";

axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.Accept = "application/json";
axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.baseURL =
    process.env.NODE_ENV === "development"
        ? "http://localhost:8000/api"
        : "/api";

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers["X-CSRF-TOKEN"] = token.content;
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token",
    );
}

export default axios;
