// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SmsInput-module__input___9_KIC{width:60px;height:58px;background:var(--color-white);border:1px solid var(--color-border);box-sizing:border-box;border-radius:12px;display:flex;align-items:center;justify-content:center;font-size:20px;line-height:16px;font-family:var(--default-font);text-align:center}.SmsInput-module__input___9_KIC+.SmsInput-module__input___9_KIC{margin-left:16px}.SmsInput-module__input___9_KIC:focus{box-shadow:0 0 0 2px rgba(64,132,248,0.16);border-color:var(--color-focused)}.SmsInput-module__input--isSubmitting___hsMA9{pointer-events:none;border-color:var(--color-border);color:var(--color-loading_text)}.SmsInput-module__input--error___1FPHL{border-color:var(--color-red);box-shadow:0 0 0 2px rgba(250,89,89,0.16)}\n", ""]);
// Exports
exports.locals = {
	"input": "SmsInput-module__input___9_KIC",
	"input--isSubmitting": "SmsInput-module__input--isSubmitting___hsMA9",
	"input--error": "SmsInput-module__input--error___1FPHL"
};
module.exports = exports;
