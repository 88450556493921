/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import isEmpty from "lodash/isEmpty";
import axios from "./axiosService";

const combineHeaders = (headers) => {
    if (headers) return headers;

    return headers;
};

const setHttpToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common.Authorization = null;
    }
};

const prepareParams = (getParams) => {
    const result = {};
    for (const prop in getParams) {
        if (Array.isArray(getParams[prop])) {
            result[prop] = getParams[prop].reduce(
                (acc, cur) => (acc += `${cur},`),
                "",
            );

            continue;
        }

        if (
            getParams[prop] ||
            getParams[prop] === "" ||
            typeof getParams[prop] === "boolean" ||
            typeof getParams[prop] === "number"
            // || !Array.isArray(getParams[prop])
        ) {
            result[prop] = getParams[prop];
        }
    }

    return result;
};

const parsePayload = (payloadData) => {
    const result = {};
    if (payloadData) {
        const payload = { ...payloadData };

        if (!isEmpty(payload)) {
            if (payload.getParams)
                result.params = prepareParams(payload.getParams);
            if (payload.data) result.data = payload.data;
        }
    }
    return result;
};

const combineUrl = (initialUrl, params) => {
    let url = initialUrl;
    if (params) {
        const getParams = params;
        url = getParams ? `${url}?${getParams}` : url;
    }
    return url;
};

const isSuccessStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return true;
    }
    return false;
};

const getResponseMessage = (originalResponse) => {
    let message = "";

    if (originalResponse) {
        const response = originalResponse.data
            ? originalResponse.data
            : originalResponse.response;

        if (response && response.data) {
            const { messages } = response.data;

            if (messages instanceof Array) {
                for (let i = 0; i < messages.length; i++) {
                    for (let j = 0; j < messages[i].length; j++) {
                        const comma = j === messages[i].length - 1 ? "." : ", ";
                        message += `${messages[i][j]}${comma}`;
                    }
                }
            }
        }
    }

    return message;
};

const handleResponseData = (response) => {
    if (response.content) return response.content;
    if (response.data) return response.data;

    return response;
};

export {
    combineHeaders,
    setHttpToken,
    parsePayload,
    combineUrl,
    getResponseMessage,
    isSuccessStatus,
    handleResponseData,
};
