import React from "react";

/* Components */
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarWrapper = ({ open, handleClose, errMess, severity }) => (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
            {errMess}
        </Alert>
    </Snackbar>
);

export default SnackbarWrapper;
